export default function admin(state = {
  editandoMedio: false
}, action) {
  switch (action.type) {
    case 'INICIOEDITARMEDIO':
      return {
        ...state,
        editandoMedio: true
      }
    case 'FINEDITARMEDIO':
      return {
        ...state,
        editandoMedio: false
      }
    default:
      return state
  }
}
