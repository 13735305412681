
import { createStore, applyMiddleware, compose  } from 'redux';
import rootReducer from '../reducers';
import middlewares from '../middleware';

export default function configureStore(initialState) {

  const composeEnhancers = (typeof (window) == "undefined") ?
    compose
    :
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer(),
    initialState,
    composeEnhancers(
      applyMiddleware(...middlewares)
    ),
  )
  return store;
  /*
  const createStoreWithMiddlewares = applyMiddleware(...middlewares)(createStore);

  console.log("after createStoreWithMiddlewares");



  return createStoreWithMiddlewares(rootReducer(initialState),
    (typeof window === 'undefined') ? undefined : window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );*/
}
