import React, { Children } from 'react'
import emergence from 'emergence.js'

import Navi from 'components/navi'
import Footer from 'components/footer'
import { siteMetadata } from '../../../gatsby-config'




import 'modern-normalize/modern-normalize.css'
import 'prismjs/themes/prism.css'
import 'scss/styles.scss'
//import 'animate.css/animate.css'
import 'font-awesome/css/font-awesome.css'

class Layout extends React.Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    emergence.init()
    if (this.props.isMobile) {
      this.layout.className = "isMobile";
    }

  }

  componentDidUpdate() {
    emergence.init()
  }


  setLayoutRef = (layout) => {
    this.layout = layout;
  }

  render() {
    const { children } = this.props
    const menuItems = [
      {
        text: "Pagina 2",
        href: "/page2"
      },
      {
        text: "LOGIN",
        href: "/play/login"
      }

    ];

    return (
      <div ref={this.setLayoutRef}>
          <Navi  {...this.props} apiBaseURL={siteMetadata.apiBaseURL} title={siteMetadata.title} MenuItems={menuItems} />
          {children}
          <Footer {...this.props} url={siteMetadata.siteUrl} title={siteMetadata.title} author={siteMetadata.author} />
        </div>
    )
  }
}

export default Layout
