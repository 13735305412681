import axios from "axios"
import { siteMetadata } from '../../gatsby-config'
import { LocalStorageManager } from "../utils/localStorageManager"
import { VOTOPREFIX, MISVOTOS } from "../utils/constantes"
const qs = require('querystring')


const localStorageManager = new LocalStorageManager();


function recibirComentarios(comentarios, idMedio) {
  return {
    type: "RECIBIRCOMENTARIOS",
    comentarios,
    idMedio
  }
}

function errorOnRecibirComentarios(errorMessage) {
  return {
    type: "ERRORONRECIBIRCOMENTARIOS",
    errorMessage
  }
}

function errorOnPostComentario(errorMessage) {
  return {
    type: "ERRORONPOSTCOMENTARIO",
    errorMessage
  }
}


const fetchComentarios = (idMedio, dispatch) => {
  let baseurl = siteMetadata.apiBaseURL;
  let url = baseurl + "/App_HttpHandlers/MobileAppHandler.ashx?accion=getcomentarios&IdMedio=" + idMedio
  axios
    .get(url)
    .then(({ data }) => {
      dispatch(recibirComentarios(data, idMedio));
    })
    .catch(error => {
      console.log(error);
      dispatch(errorOnRecibirComentarios(error));
    })
}


const postComentario = (idMedio, nuevoComentario, nombre, idComentarioResponder, refComentar, dispatch) => {
  let baseurl = siteMetadata.apiBaseURL;
  let url = baseurl + "/App_HttpHandlers/MobileAppHandler.ashx";

  const postConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  let sendData = qs.stringify({
    accion: "addcomentario",
    IdMedio: idMedio,
    Texto: nuevoComentario,
    Nombre: nombre,
    IdComentarioRespondido: idComentarioResponder || 0,
  })

  axios
    .post(url, sendData , postConfig)
    .then(({ data }) => {
      //dispatch(recibirComentarios(data, idMedio));
      //dispatch({ type: `REQUESTCOMENTARIOS`, idMedio })
      refComentar.callbackComentar(refComentar);
    })
    .catch(error => {
      console.log(error);
      dispatch(errorOnPostComentario(error));
    })
}


const cambiarEstadoComentario = (idComentario, nuevoEstado, refComentario, callback, dispatch) => {
  let baseurl = siteMetadata.apiBaseURL;
  let url = baseurl + "/App_HttpHandlers/MobileAppHandler.ashx";
  axios
    .get(url, {
      params: {
        accion: "cambiarestadocomentario",
        IdComentarioRespondido: idComentario,
        EstadoComentarioRespondido: nuevoEstado
      }
    })
    .then(({ data }) => {
      refComentario.cambiarEstadoCallback(refComentario, nuevoEstado);
    })
    .catch(error => {
      console.log(error);
      dispatch(errorOnPostComentario(error));
    })
}



const rate = (idMedio, meGusta, sumar, refRate, callback, dispatch) => {
  let baseurl = siteMetadata.apiBaseURL;
  let url = baseurl + "/App_HttpHandlers/MobileAppHandler.ashx";
  axios
    .get(url, {
      params: {
        accion: "addvoto",
        IdMedio: idMedio,
        EsMeGusta: meGusta,
        Sumar: sumar
      }
    })
    .then(({ data }) => {

      if (callback !== undefined) {
        callback(refRate)
      }
      else {
        let misVotos = localStorageManager.getAsObject(MISVOTOS)
        if (misVotos == null) {
          misVotos = {}
        }

        if (sumar) {
          misVotos[VOTOPREFIX + idMedio] = meGusta;
        } else {
          delete misVotos[VOTOPREFIX + idMedio];
        }

        localStorageManager.set(MISVOTOS, misVotos)
        refRate.updateVotoState(refRate)
      }

    })
    .catch(error => {
      console.log(error);
      dispatch(errorOnPostComentario(error));
    })
}


export default function comentarios(
  state = {
    loading: false,
    error: false,
    idMedio: "",
    errorMessage: "",
    comentarios: [],
  }, action) {
  switch (action.type) {
    case 'REQUESTCOMENTARIOS':
      fetchComentarios(action.idMedio, action.asyncDispatch)
      return {
        ...state,
        loading: true,
        error: false,
        comentarios: [],
        errorMessage: null,
      }
    case 'POSTCOMENTARIO':
      postComentario(
        action.idMedio,
        action.comentarioNuevo,
        action.nombre,
        action.idComentarioResponder,
        action.refComentar,
        action.asyncDispatch)
      comentarios = state.comentarios
      comentarios.push(
        {
          "IdComentario": -1,
          "Fecha": new Date(),
          "IdComentarioRespondido": 0,
          "IdEstadoComentario": -1,
          "IdMedio": action.IdMedio,
          "Nombre": action.nombre,
          "Texto": action.comentarioNuevo
        }
      );
      return {
        ...state,
        comentarios,
        loading: true,
        error: false,
        errorMessage: null,
      }
    case 'CAMBIARESTADOCOMENTARIO':
      cambiarEstadoComentario(
        action.idComentario,
        action.nuevoEstado,
        action.refComentario,
        action.asyncDispatch)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
      }
    case 'RECIBIRCOMENTARIOS':
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        idMedio: action.idMedio,
        comentarios: action.comentarios ? action.comentarios : [],
      }
    case 'ERRORONRECIBIRCOMENTARIOS':
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
        comentarios: [],
      }
    case 'ERRORONPOSTCOMENTARIO':
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
        comentarios: [],
      }
    case 'RATEMEDIO':
      rate(action.idMedio,
        action.like,
        action.sumar,
        action.refRate,
        action.callback,
        action.asyncDispatch)
      return state
    default:
      return state
  }




}
