import axios from "axios"

function receiveHome(data) {
  return {
    type: "RECEIVEHOME",
    franjas: data.Hijos,
    destacado: data.Destacado,
    receivedAt: Date.now()
  }
}

function errorOnReceiveHome(errorMessage) {
  return {
    type: "ERRORONRECEIVEHOME",
    errorMessage
  }
}

const fetchHome = (dispatch, baseurl) => {
  let url = baseurl + "/App_HttpHandlers/MobileAppHandler.ashx"
  axios
    .get(url, {
      params: {
        accion: "search",
        TipoDeBusqueda: "PROPIEDAD",
        Propiedad: "ordenFranjas",
        Profundidad: 1,
        Top: 100
      }
    })
    .then(({ data }) => {
      dispatch(receiveHome(data));
    })
    .catch(error => {
      console.log(error);
      dispatch(errorOnReceiveHome(error));
    })
}


export default function Home(
  state = {
    loading: false,
    error: false,
    errorMessage: "",
    franjas: [],
    lastLoad: null,
  }, action) {
  switch (action.type) {
    case 'LOADHOME':
      fetchHome(action.asyncDispatch, action.baseurl)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        franjas: [],
      }
    case 'RECEIVEHOME':
      return {
        loading: false,
        error: false,
        errorMessage: null,
        franjas: action.franjas ? action.franjas : [],
        destacado: action.destacado,
        lastLoad: action.receivedAt
      }
    case 'ERRORONRECEIVEHOME':
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
        franjas: [],
      }
    default:
      return state
  }
}
