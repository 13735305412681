import { Arbol } from "../utils/arbol"
import axios from "axios"
import { siteMetadata } from '../../gatsby-config'


function receiveCategorias(categorias) {
  return {
    type: "SETUPARBOLCATEGORIAS",
    categorias
  }
}

const fetchCategorias = (dispatch) => {
  let url = siteMetadata.apiBaseURL + "/App_HttpHandlers/TableToJSON.ashx?key=7c6f2d6f-4a99-42a5-bb34-92fe71cab090&orderBy=IdPadre"
  axios
    .get(url)
    .then(({ data }) => {
      dispatch(receiveCategorias(data));
    })
    .catch(error => {
      console.log("ERROR", error);
    })
}

export default function arbolCategorias(
  state = new Arbol(),
  action) {
  switch (action.type) {
    case "LOADCATEGORIAS":
      fetchCategorias(action.asyncDispatch);
      return state
    case 'SETUPARBOLCATEGORIAS':
      state = new Arbol(action.categorias)
      return state
    default:
      return state
  }
}
