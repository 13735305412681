export default function playlist(state = {
  medios: [],
  actual: 0
},
  action) {
  let newPlaylist;
  switch (action.type) {
    case 'ADDTOPLAYLIST':
      //newPlaylist = state.medios.concat([action.medio]);
      newPlaylist = [action.medio];
      return {
        ...state,
        medios: newPlaylist
      }
    case 'REMOVEFROMPLAYLIST':
      state.medios.splice(action.position, 1);
      newPlaylist = state.medios.concat(state.medios);
      return {
        ...state,
        medios: newPlaylist
      }
    case 'SHOWPLAYERINFOCONTAINER':
      return {
        ...state,
        showingDetails: true,
        tabKey: action.tabKey
      }
    case 'HIDEPLAYERINFOCONTAINER':
      return {
        ...state,
        showingDetails:false
      }
    default:
      return state
  }
}
