import React from "react"
import NodoArbol from "./NodoArbol"
import { connect } from "react-redux"
import clonedeep from "lodash.clonedeep"


let keyPressTimeout = null;
let ArbolResultado = {};
class ArbolCategorias extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps &&
      (typeof (this.state.selected) == "undefined"
        || this.props.selected != this.state.selected)) {
      /*this.props.selected.map((item, i) => {
        this.props.selected[i] = item + "";
      })
      console.log(this.props.selected)
      */
      this.setState({
        selected: this.props.selected !== undefined ? this.props.selected : []
      });
      try {
        this.setSelected(ArbolResultado.raiz)
      } catch (e) {
        console.log(e);
      }

    }
  }

  componentDidMount() {
    // console.log("this.props.arbolCategorias", this.props.arbolCategorias)
  }

  state = {
    filter: null,
    selected: []
  }

  handleLimpiarFiltro = (e) => {
    let arbol = this.props.arbolCategorias
    this.InputFiltro.value = "";
    this.setState({ filtro: null });
    this.filtrar(arbol.raiz, null);
  }


  handleFiltro = (e) => {
    //console.log(e)
    let value = e.target.value;
    let arbol = this.props.arbolCategorias


    if (keyPressTimeout) {
      clearTimeout(keyPressTimeout);
    }

    if (value.length > 2) {
      let regexFilter = new RegExp(value.replace(/[\\\*\+]/g, ""), "i");


      keyPressTimeout = setTimeout(() => {
        this.filtrar(arbol.raiz, regexFilter);
        this.setState({
          filter: value
        })
      }
        , 500);
    } else {
      keyPressTimeout = setTimeout(() => {
        this.filtrar(arbol.raiz, null);
        this.setState({
          filter: null
        })
      }
        , 500);
    }
  }

  filtrar(nodo, regexFilter, padre) {
    let keys = Object.keys(nodo)
    keys.forEach((key) => {
      let current = nodo[key];
      if (regexFilter && current.NombreCategoria.match(regexFilter)) {
        current.mostrar = true;
      } else {
        current.mostrar = false;//regexFilter == null;
      }

      if (current.hijos) {
        this.filtrar(current.hijos, regexFilter, current)
      }

      if (current.mostrar && padre !== undefined) {
        padre.mostrar = true;
      }
    })
  }

  setSelected(nodo, padre) {
    if (nodo === undefined) return;
    let keys = Object.keys(nodo)
    keys.forEach((key) => {
      let current = nodo[key];
      if (this.state.selected !== undefined
        && this.state.selected.indexOf(current.IdCategoria) != -1) {
        current.mostrar = true;
      } else {
        current.mostrar = false;
      }

      if (current.hijos) {
        this.setSelected(current.hijos, current)
      }

      if (current.mostrar && padre !== undefined) {
        padre.mostrar = true;
      }
    })
  }


  componentDidMount() {
    let arbol = this.props.arbolCategorias
    ArbolResultado = clonedeep(this.props.arbolCategorias)
    this.filtrar(arbol.raiz, null);
    this.setState({
      filter: null
    })
  }


  SetInputFiltro = input => {
    this.InputFiltro = input
  }


  render() {
    let arbol = this.props.arbolCategorias
    this.setSelected(ArbolResultado.raiz)

    //console.log("arbol", arbol);
    return (
      <>
        {/*
        <div>
          {this.state.selected.map((selected, i) => {
            return <span key={i}> {selected} </span>
          })}
        </div>
         */}



        <div className="row">
          <div className="col-sm-6 arbolCategorias">
            <h2>Buscar categor&iacute;a</h2>
            <input className="form-control-plaintext"
              ref={this.SetInputFiltro}
              type="text"
              onChange={this.handleFiltro} />
            {/*
              <button onClick={this.handleLimpiarFiltro}>Limpiar</button>
            */}
            <NodoArbol medio={this.props.medio} id="CategoriasFiltradas" refArbol={this} nodo={arbol.raiz} />
          </div>
          <div className="col-sm-6 arbolCategorias">
            <h2>Categorías seleccionadas</h2>
            <NodoArbol medio={this.props.medio} id="CategoriasResultado" refArbol={this} nodo={ArbolResultado.raiz} />
          </div>
        </div>





      </>
    )
  }
}


const mapStateToProps = ({ arbolCategorias }) => {
  return {
    arbolCategorias
  }
}

const mapDispatchToProps = dispatch => {
  return {
    Alert: (title, text) => {
      dispatch({
        type: `SHOWUSERMESSAGE`, title, text
      })
    },
  }
}

const ConnectedArbolCategorias = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArbolCategorias)


export default ConnectedArbolCategorias
