
export const VOTOPREFIX = "voto"
export const MISVOTOS = "MisVotos"
export const COMENTARIOPREFIX = "com";
export const SESSIONID = "sid";

export const ATRIBUTOPREFIX = "ATR";

export const CATEDRAIDCATEGORIA = 82;
export const ASIGNATURAIDCATEGORIA = 83;
