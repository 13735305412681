import axios from "axios"
import { siteMetadata } from '../../gatsby-config'


function receiveAtributos(atributos) {
  return {
    type: "RECEIVEATRIBUTOS",
    atributos
  }
}

const fetchAtributos = (dispatch) => {
  let baseurl = siteMetadata.apiBaseURL;
  let url = baseurl + "/App_HttpHandlers/MobileAppHandler.ashx";
  axios
    .get(url, {
      params: {
        accion: "getatributos"
      }
    })
    .then(({ data }) => {
      dispatch(receiveAtributos(data));
    })
    .catch(error => {
      console.log("ERROR", error);
    })
}

export default function atributos(
  state = [], action ){
  switch (action.type) {
    case "LOADATRIBUTOS":
      fetchAtributos(action.asyncDispatch);
      return state
    case 'RECEIVEATRIBUTOS':
      state = action.atributos
      return state
    default:
      return state
  }
}
