export default function userMessage(state = {
  show: false,
  message: {}
}, action) {
  switch (action.type) {
    case 'SHOWUSERMESSAGE':
      return {
        show: true,
        title: action.title,
        text: action.text
      }
    case 'CLOSEUSERMESSAGE':
      return {
        show: false
      }
    default:
      return state
  }
}
