import React, { useState, useRef } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from 'gatsby'
import { siteMetadata } from '../../../gatsby-config'

import Modal from 'react-bootstrap/Modal';
import EditVideo from "./EditVideo"
import axios from "axios"


export default function (props) {
  const { session, playlist, atributos } = useSelector(state => state)
  const dispatch = useDispatch()

  const [adminMenuState, setAdminMenuState] = useState({
    showModal: false,
    showAtributosModal: false,
    showCategoriasModal: false,
    showConfirmDeleteModal: false,
    medio: null
  });

  const handleNuevoClick = () => {
    dispatch({ type: 'INICIOEDITARMEDIO' });

    setAdminMenuState({
      ...adminMenuState,
      showModal: true,
      medio: null
    });
  }

  const handleGuardar = (e) => {
    //console.log("refVideoEdit", refVideoEdit)
    //adminMenuState.EditVideo.GuardarTodo();
  }


  const handleEditClick = () => {
    dispatch({ type: 'INICIOEDITARMEDIO' });

    let medio = playlist.medios[0]
    setAdminMenuState({
      ...adminMenuState,
      showModal: true,
      medio
    });
  }


  const handleDeleteClick = () => {
    let medio = playlist.medios[0]
    setAdminMenuState({
      ...adminMenuState,
      showConfirmDeleteModal: true,
      medio
    });
  }

  const handleCloseConfirmDelete = () => {
    setAdminMenuState({
      ...adminMenuState,
      showConfirmDeleteModal: false
    });
  }

  const handleDeleteMedio = () => {
    let medio = adminMenuState.medio
    let baseurl = siteMetadata.apiBaseURL;
    let url = baseurl + "/App_HttpHandlers/MobileAppHandler.ashx";
    axios
      .get(url, {
        params: {
          accion: "deletemedio",
          IdMedio: medio.IdMedio
        }
      })
      .then(({ data }) => {
        setAdminMenuState({
          ...adminMenuState,
          showConfirmDeleteModal: false
        });
        dispatch({ type: `LOADHOME`, baseurl })
        navigate("/");
        Alert("Eliminar medio", "Medio eliminado exitosamente");
      })
      .catch(error => {
        console.log("ERROR", error);
      })
  }




  const handleLogout = (e) => {
    e.preventDefault();
    dispatch({ type: 'LOGOUT' })
  }


  const handleClose = (e) => {
    let confirmClose = confirm("Está seguro que desea cerrar?")
    if (confirmClose) {
      dispatch({ type: 'FINEDITARMEDIO' });
      setAdminMenuState({
        ...adminMenuState,
        showModal: false,
        medio: null
      });
    }
  }



  const handleAtributosClick = () => {
    setAdminMenuState({
      ...adminMenuState,
      showAtributosModal: true
    });
  }

  const handleCloseAtributos = (e) => {
    setAdminMenuState({
      ...adminMenuState,
      showAtributosModal: false
    });
    dispatch({ type: 'LOADATRIBUTOS' })
  }

  const handleCategoriasClick = () => {
    setAdminMenuState({
      ...adminMenuState,
      showCategoriasModal: true
    });
  }

  const handleCloseCategorias = (e) => {
    setAdminMenuState({
      ...adminMenuState,
      showCategoriasModal: false
    });
    dispatch({ type: 'LOADCATEGORIAS' })
  }

  let baseurl = siteMetadata.apiBaseURL;
  let sid = session.id || null
  let urlAtributos = `${baseurl}/controlpanel/catalogos/atributos.aspx?sessionId=${sid}`;
  let urlCategorias = `${baseurl}/controlpanel/admin/categorias.aspx?sessionId=${sid}`;

  if (sid && atributos.length == 0) {
    dispatch({ type: 'LOADATRIBUTOS' })
  }

  const refGuardar = useRef(null);

  const Alert = (title, text) => {
    dispatch({
      type: `SHOWUSERMESSAGE`, title, text
    })
  }

  return (
    <div>
      {sid ?
        <div>
          <div id="AdminMenu">
            <h1>Admin</h1>
            <a className="btn" onClick={handleNuevoClick}>Agregar medio</a>

            {
              playlist.medios.length ?
                <>
                  <a className="btn" onClick={handleEditClick}>Editar medio</a>
                  <a className="btn" onClick={handleDeleteClick}>Borrar medio</a>
                </>
                : null
            }


            <a className="btn" onClick={handleAtributosClick} target="_blank" >Editor atributos</a>
            <a className="btn" onClick={handleCategoriasClick} target="_blank" >Editor categorías</a>
            <a className="btn" onClick={handleLogout}>Salir</a>
          </div>

          <Modal
            backdrop={true}
            size="lg"
            id="EditVideoModal"
            show={adminMenuState.showModal}
            onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="w-100 text-center">Administrar medio</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
              <div className="d-flex bd-highlight">
                <div className="p-2 flex-grow-1 bd-highlight">
                  <EditVideo GuardarButton={refGuardar} medio={adminMenuState.medio} />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {
                adminMenuState.medio == null ?
                  <button ref={refGuardar} className="btn btn-primary">Guardar</button>
                  : null
              }
              <button className="btn btn-danger" onClick={handleClose}>Cerrar</button>
            </Modal.Footer>
          </Modal>

          <Modal
            backdrop={true}
            dialogClassName="modal-90w"
            id="EditAtributosModal"
            show={adminMenuState.showAtributosModal}
            onHide={handleCloseAtributos}>
            <Modal.Header closeButton>
              <Modal.Title className="w-100 text-center">Administrar Atributos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <iframe width="100%" height="600px" src={urlAtributos} />
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-danger" onClick={handleCloseAtributos}>Cerrar</button>
            </Modal.Footer>
          </Modal>

          <Modal
            backdrop={true}
            dialogClassName="modal-90w"
            id="EditCategoriasModal"
            show={adminMenuState.showCategoriasModal}
            onHide={handleCloseCategorias}>
            <Modal.Header closeButton>
              <Modal.Title className="w-100 text-center">Administrar Categorias</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <iframe width="100%" height="600px" src={urlCategorias} />
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-danger" onClick={handleCloseCategorias}>Cerrar</button>
            </Modal.Footer>
          </Modal>


          <Modal
            backdrop={true}
            id="DeleteModal"
            centered
            show={adminMenuState.showConfirmDeleteModal}
            onHide={handleCloseConfirmDelete}>
            <Modal.Header closeButton>
              <Modal.Title className="w-100 text-center">Eliminar medio</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
              <p>
                Está seguro que desea eliminar el medio {adminMenuState.medio ? adminMenuState.medio.Titulo : ""}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-danger" onClick={handleDeleteMedio}>Eliminar</button>
              <button className="btn btn-danger" onClick={handleCloseConfirmDelete}>Cancelar</button>
            </Modal.Footer>
          </Modal>
        </div>

        : null}
    </div>

  )

}
