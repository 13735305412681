import React from "react"
import { Provider } from "react-redux"
import Layout from './src/components/layout'
import configureStore from './src/store';
import { setTimeout } from "core-js";
import { LocalStorageManager } from "./src/utils/localStorageManager"
import { SESSIONID } from "./src/utils/constantes"
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import { siteMetadata } from './gatsby-config'

const apiBaseURL = siteMetadata.apiBaseURL;
const initialState = { count: 1000 };
const localStorageManager = new LocalStorageManager();
let sid = localStorageManager.get(SESSIONID);

if (sid !== undefined) {
  initialState["session"] = {
    loading: false,
    error: false,
    errorMessage: "",
    showModal: false,
    id: sid
  }
}

const store = configureStore(initialState);

axios.interceptors.request.use(request => {
  let sid = localStorageManager.get(SESSIONID);
  if (sid !== undefined && sid) {
    if (request.params === undefined) {
      request.params = {}
    }
    request.params["sessionId"] = sid;
  }
  //console.log("axios.interceptors.request",request);
  // Edit request config
  return request;
}, error => {
  console.log(error);
  return Promise.reject(error);
});


axios.interceptors.response.use(response => {
  //console.log("axios.interceptors.response",response);
  // Edit response config
  let { session } = store.getState();
  if (session.id
    && (response.headers.autenticado !== undefined)
    && (response.headers.autenticado == "false")) {
    store.dispatch({ type: "LOGOUT" })
  }

  return response;
}, error => {
  console.log(error);
  return Promise.reject(error);
});

const pingApi = () => {
  axios.head(`${apiBaseURL}/App_HttpHandlers/MobileAppHandler.ashx?action=ping`);
}

setInterval(() => {
  pingApi()
},
  1000 * 60);


//setTimeout(() => { store.dispatch({ type: 'HIDENAV' }) }, 5000);

class WrapWithProvider extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
  }

  componentDidMount() {
    //setTimeout(() => { store.dispatch({ type: 'HIDENAV' }) }, 2000);
    this.lastMove = Date.now()
    window.addEventListener("focus", this.onFocus)
    window.addEventListener("blur", this.onBlur)
    //window.addEventListener("beforeunload", (e) => {
    //  e.preventDefault();
    //  console.log(e)
    //  return e.returnValue = confirm('Are you sure you want to close?');
    //});
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus)
    window.removeEventListener("blur", this.onBlur)
  }

  lastMove = null

  autoHide = null;

  setupHideNav = () => {
    /*AUTOHIDE*/
    if (this.autoHide) {
      clearTimeout(this.autoHide);
    }
    this.autoHide = setTimeout(() => {
      let currentState = store.getState();
      if (currentState.nav.timeoutHide) {
        store.dispatch({ type: 'HIDENAV' })
      }
    }, 5000);
  }


  handleMouseMove = (event) => {
    let currentState = store.getState();
    if (currentState.nav.timeoutHide) {
      if ((Date.now() - this.lastMove) > 500) {
        if (!currentState.nav.mostrarNavBar) {
          store.dispatch({ type: 'SHOWNAV' });
        }
        this.lastMove = Date.now();
        this.setupHideNav(currentState);
      }
    }
  }

  componentDidUpdate(prevProps) {
    let currentState = store.getState();
    if (currentState.nav.timeoutHide
      && this.autoHide == null) {
      this.setupHideNav()
    } else {
      clearTimeout(this.autoHide);
    }
  }

  onFocus(e) {
    pingApi();
  }

  onBlur(e) {
    //console.log("blur")
  }

  render() {
    const { children } = this.props
    return (
      <Provider {...this.props} store={store}>
        <div id="GlobalDIV"
          onMouseMove={this.handleMouseMove}
          onTouchStart={this.handleMouseMove}

        >
          <Layout {...this.props} location={typeof location === 'undefined' ? undefined : location}>
            {children}
          </Layout>
        </div>
      </Provider>
    )
  }
}



export default ({ element }) => {
  return (
    <WrapWithProvider isMobile={isMobile} store={store}>
      {element}
    </WrapWithProvider>
  )
}

