import axios from "axios"
import { siteMetadata } from '../../gatsby-config'
import { object } from "prop-types";
import { LocalStorageManager } from "../utils/localStorageManager"
import { SESSIONID } from "../utils/constantes"


const localStorageManager = new LocalStorageManager();

function recibirRespuestaLogin(WebsiteSessionID) {
  localStorageManager.set(SESSIONID, WebsiteSessionID)
  axios.defaults.params = { sessionId: WebsiteSessionID};

  return {
    type: "RECIBIRRESPUESTALOGIN",
    WebsiteSessionID
  }
}

function errorOnRecibirRespuestaLogin(errorMessage) {
  return {
    type: "ERRORONRECIBIRRESPUESTALOGIN",
    errorMessage
  }
}


function recibirRespuestaLogout() {
  localStorageManager.remove(SESSIONID)
  axios.defaults.params = {};
  return {
    type: "RECIBIRRESPUESTALOGOUT",
  }
}

function errorOnRecibirRespuestaLogout(errorMessage) {
  return {
    type: "ERRORONRECIBIRRESPUESTALOGOUT",
    errorMessage
  }
}

const login = (username, password, dispatch) => {
  let url = siteMetadata.apiBaseURL + "/App_HttpHandlers/AutenticarHandler.ashx"
  axios
    .get(url, {
      params: {
        action: "autenticar",
        idUser: username,
        password: password
      }
    })
    .then(({ data }) => {
      if (data.Autenticado) {
        dispatch(recibirRespuestaLogin(data.WebsiteSessionID));
      } else {
        dispatch(errorOnRecibirRespuestaLogin(data.OutMessage));
        dispatch({
          type: "SHOWUSERMESSAGE",
          title: "Error",
          text: data.OutMessage
        });
      }
    })
    .catch(error => {
      console.log(error);
      if (typeof (error) == "object") {
        error = error.message
      }

      dispatch(errorOnRecibirRespuestaLogin(error));
    })
}

const logout = (dispatch) => {
  let url = siteMetadata.apiBaseURL + "/App_HttpHandlers/LogoutHandler.ashx"
  axios
    .get(url, {
      params: {
        action: "logout",
      }
    })
    .then(({ data }) => {
      dispatch(recibirRespuestaLogout());
    })
    .catch(error => {
      console.log(error);
      if (typeof (error) == "object") {
        error = error.message
      }
      dispatch(errorOnRecibirRespuestaLogout(error));
    })
}

export default function session(
  state = {
    loading: false,
    error: false,
    errorMessage: "",
    showModal: false,
    id: ""
  }, action) {

  switch (action.type) {
    case 'SHOWLOGIN':
      return {
        ...state,
        error: false,
        errorMessage: "",
        showModal: true
      }
    case 'HIDELOGIN':
      return {
        ...state,
        error: false,
        errorMessage: "",
        showModal: false
      }
    case 'LOGIN':
      login(action.username, action.password, action.asyncDispatch)
      return {
        ...state,
        loading: true
      }
    case 'RECIBIRRESPUESTALOGIN':
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        showModal: false,
        id: action.WebsiteSessionID
      }
    case 'ERRORONRECIBIRRESPUESTALOGIN':
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
        id: "",
      }
    case 'LOGOUT':
      logout(action.asyncDispatch)
      return {
        ...state,
        loading: true
      }
    case 'RECIBIRRESPUESTALOGOUT':
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        showModal: false,
        id: ""
      }
    case 'ERRORONRECIBIRRESPUESTALOGOUT':
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      }

    default:
      return state
  }




}
