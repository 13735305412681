import axios from "axios"
import { siteMetadata } from '../../gatsby-config'


function recibirResultadoBusqueda(resultadoBusqueda,query) {
  return {
    type: "RECIBIRRESULTADOBUSQUEDA",
    resultadoBusqueda,
    query,
    receivedAt: Date.now()
  }
}

function errorOnRecibirResultadoBusqueda(errorMessage) {
  return {
    type: "ERRORONRECIBIRRESULTADOBUSQUEDA",
    errorMessage
  }
}

// This data is fetched at run time on the client.
const realizarBusquedaTexto = (query, dispatch) => {
  let url = siteMetadata.apiBaseURL + "/App_HttpHandlers/MobileAppHandler.ashx?accion=search&TipoDeBusqueda=TEXTO&Texto=" + query
  axios
    .get(url)
    .then(({ data }) => {
      dispatch(recibirResultadoBusqueda(data, query));
    })
    .catch(error => {
      console.log(error);
      dispatch(errorOnRecibirResultadoBusqueda(error));
    })
}


export default function busquedaTexto(
  state = {
    showModal: false,
    loading: false,
    error: false,
    query:"",
    errorMessage: "",
    resultadoBusqueda: [],
    lastLoad: null,
  }, action) {
  switch (action.type) {
    case 'BUSCARTEXTO':
      realizarBusquedaTexto(action.query,action.asyncDispatch)
      return {
        ...state,
        loading: true,
        error: false,
        resultadoBusqueda:[],
        errorMessage: null,
      }
    case 'RECIBIRRESULTADOBUSQUEDA':
      return {
        ...state,
        loading : false,
        error: false,
        errorMessage: null,
        query: action.query,
        resultadoBusqueda: action.resultadoBusqueda ? action.resultadoBusqueda : [],
        lastLoad : action.receivedAt
      }
    case 'ERRORONRECIBIRRESULTADOBUSQUEDA':
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
        resultadoBusqueda: [],
      }

    case "SHOWMODALBUSQUEDATEXTO":
      return {
        ...state,
        showModal: true
      }
    case "HIDEMODALBUSQUEDATEXTO":
      return {
        ...state,
        showModal: false
      }
    default:
      return state
  }




}
