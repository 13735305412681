class Arbol {
  constructor(categorias = null) {
    if (categorias) {
      this.arbolCategorias(categorias)
    }
  }

  raiz = null

  cargarArbol(raiz) {
    this.raiz = raiz
  }

  agregarNodo(rama, categoria) {
    rama["cat-" + categoria.IdCategoria] = categoria;
    this.revisarHuerfanos(categoria);
  }

  revisarHuerfanos(categoria) {
    let self = this
    Object.keys(self.raiz).forEach(function (key) {
      let item = self.raiz[key]
      if (item.IdPadre == categoria.IdCategoria) {
        if (categoria.hijos === undefined) {
          categoria.hijos = {}
        }
        self.agregarNodo(categoria.hijos, item)
        delete self.raiz[key]
      }
    })
  }

  buscarRama(rama, idCategoria) {
    let result = rama["cat-" + idCategoria];

    let self = this
    if (typeof (rama) != "undefined") {
      if (!result || typeof (result) == "undefined") {
        Object.keys(rama).forEach(function (key) {
          let item = rama[key]
          if (item.hijos) {
            result = self.buscarRama(item.hijos, idCategoria);
          }
          if (result) {
            return result;
          }
        });
      }
    }
    return result;
  }

  arbolCategorias = function (categorias) {
    //ordenar categorias alfabeticamente
    categorias.sort(function (a, b) {
      var textA = a.NombreCategoria.replace(a.Codigo,"").toUpperCase().trim();
      var textB = b.NombreCategoria.replace(b.Codigo, "").toUpperCase().trim();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    let self = this
    self.raiz = {}
    categorias.map((categoria, i) => {
      if (!categoria.IdPadre) {
        this.agregarNodo(self.raiz, categoria)
      } else {
        let rama = this.buscarRama(self.raiz, categoria.IdPadre)
        if (rama) {
          if (rama.hijos === undefined) {
            rama.hijos = {}
          }
          this.agregarNodo(rama.hijos, categoria)
        } else {
          this.agregarNodo(self.raiz, categoria)
        }
      }
    })
  }
}

export { Arbol }
