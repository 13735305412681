import React, { useMemo, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from "axios";
import ProgressBar from 'react-bootstrap/ProgressBar'
import { siteMetadata } from '../../../gatsby-config'

const baseurl = siteMetadata.apiBaseURL;


const initialState = {
  uploadProgress: 0,
  uploading: false,
  uploaded: false,
  uploadedFiles: null,
  preview: null
}

export default function Basic({ tiposAceptado, multiple, onUpload }) {
  const onDrop = useCallback(acceptedFiles => {
    let newState = { ...initialState }
    let preview = null

    if (acceptedFiles) {
      const file = acceptedFiles[0];
      let previewUrl = URL.createObjectURL(file);
      let type = file.type.split("/");


      switch (type[0]) {
        case "video":
          preview = (
            <div>
              <video className="filePreview" controls src={previewUrl} />
            </div>)
          break;
        case "audio":
          preview = (
            <div>
              <audio className="filePreview" controls src={previewUrl} />
            </div>)
          break;
        case "image":
          preview = (
            <div>
              <img className="filePreview" controls src={previewUrl} />
            </div>)
          break;
      }
    }

    newState.preview = preview;
    setUploadState(newState);

  }, [])


  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    accept: tiposAceptado,
    multiple: multiple || false
  });


  const [uploadState, setUploadState] = useState(initialState);

  const handleUploadFile = (e, file) => {
    e.preventDefault();
    e.stopPropagation();

    setUploadState({
      ...uploadState,
      uploadProgress: 0
    });


    let data = new FormData();
    data.append('file', file);
    let config = {
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        //console.log("percentCompleted", percentCompleted);
        setUploadState({
          ...uploadState,
          uploadProgress: percentCompleted,
          uploading: true

        });

      }
    };
    let url = baseurl + "App_HttpHandlers/upload.ashx"
    axios.put(url, data, config)
      .then(function ({ data }) {
        //console.log("upload", data);
        if (onUpload !== undefined) {
          onUpload(data);
        }

        setUploadState({
          ...uploadState,
          uploading: false,
          uploaded: true,
          uploadedFiles: data
        });
      })
      .catch(function (err) {
        output.className = 'container text-danger';
        output.innerHTML = err.message;
        setUploadState({
          ...uploadState,
          uploading: false,
          uploaded: false
        });
      });
  }




  const files = acceptedFiles.map(file => {

    return (
      <li key={file.path}>
        {file.path} <br />- {file.size} bytes
        <button
          className={uploadState.uploading || uploadState.uploaded ? "d-none" : ""}
          onClick={
            (e) => {
              handleUploadFile(e, file)
            }
          }> UPLOAD </button>



        {uploadState.uploading ?
          <ProgressBar now={uploadState.uploadProgress} />
          :
          null
        }

        {uploadState.uploaded ?
          <div className="uploadDone">- subido -</div>
          : null}


      </li>
    )
  }
  );



  const clases = useMemo(() => {
    let result = ["dropzone"]
    if (isDragActive) {
      result.push("active");
    }
    if (isDragAccept) {
      result.push("accepted");
    }
    if (isDragReject) {
      result.push("rejected");
    }
    return result;
  }, [
      isDragActive,
      isDragReject
    ]);




  return (
    <section className="container">
      <div {...getRootProps({ className: clases.join(" ") })}>
        <input {...getInputProps()} />
        {files.length == 0 ?
          <span className="text-center" >Arrastre y suelte los archivos a &eacute;sta zona
        </span>
          : null}
      {uploadState.preview}
        <ul>{files}</ul>
      </div>
    </section>
  );
}

