import React from "react"
import { Link } from 'gatsby'

class MenuItem extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    hover: false
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
  }

  hideSubMenu = () => {
    this.setState(
      {
        ...this.state,
        hover: false
      }
    )
  }

  render() {
    const currentItem = this.props.item;
    const menuItemClasses = ["nav-item"]
    const menuAnchorClasses = ["nav-link"]

    if (currentItem.submenu) {
      menuItemClasses.push("dropdown")
      menuAnchorClasses.push("dropdown-toggle")
      if (this.state.hover) {
        menuItemClasses.push("hover")
      }
    }



    return (
      <li className={menuItemClasses.join(" ")}
        onMouseEnter={() => {
          if (!currentItem.submenu) return null
          this.setState({ ...this.state, hover: true })
        }}
        onMouseLeave={() => { this.setState({ ...this.state, hover: false }) }}>
        <Link
          to={currentItem.href}
          className={menuAnchorClasses.join(" ")}
          onClick={this.hideSubMenu}
        >
          {currentItem.text}
        </Link>
        {currentItem.submenu ?
          <ul className="dropdown-menu"
            onClick={this.hideSubMenu}
          >
            {currentItem.submenu.map((subItem, i) =>
              (
                <MenuItem item={subItem} key={i} />
              )
            )}
          </ul>
          : null}
      </li>
    )
  }
}

export default MenuItem;


