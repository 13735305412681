import React from 'react'
import { connect } from "react-redux"
import { Link } from 'gatsby'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

class Login extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    username: "",
    password: ""
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }


  handleClose = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    this.props.HideLogin();

  }

  handleOpen = (e) => {
    e.preventDefault();
    this.props.ShowLogin();
  }

  handleLogin= (e) => {
    this.props.Login(this.state.username, this.state.password)
  }


  handleLogout = (e) => {
    e.preventDefault();
    this.props.Logout()
  }


  render() {
    const { showModal, id, error, errorMessage } = this.props.sessionState;
    return (
      <>
        {!id ?
          <a className="nav-link" onClick={this.handleOpen}>Ingresar</a>
          :
          <a className="nav-link" onClick={this.handleLogout}>Salir</a>
        }

        <Modal backdrop={true}
          centered
          id="LoginModal"
          show={showModal}
          onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="w-100 text-center">Ingresar</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <div className="d-flex bd-highlight">
              <div className="p-2 flex-grow-1 bd-highlight">
                <Form.Control
                  type="text" className="my-2"
                  value={this.state.username}
                  onChange={(event) => {
                    this.setState({ username: event.target.value })
                  }}
                  placeholder="Nombre de usuario" />
                <Form.Control
                  type="password" className="my-2"
                  value={this.state.password}
                  onChange={(event) => {
                    this.setState({ password: event.target.value })
                  }}
                  onKeyPress={(event) => {
                    if (event.charCode == 13) {
                      this.handleLogin(null);
                    }
                  }}
                  placeholder="Contraseña" />



              </div>

            </div>



          </Modal.Body>
          <Modal.Footer>
            {error ?
              <div> {errorMessage} </div>
              : null
            }

            <Button onClick={this.handleLogin}>Login</Button>
            <Button onClick={this.handleClose}>Cancelar</Button>
          </Modal.Footer>
        </Modal>

      </>
    )
  }
}

const mapStateToProps = ({ session }) => {
  return {
    sessionState: session
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ShowLogin: () => {
      dispatch({ type: 'SHOWLOGIN' })
    },
    HideLogin: () => {
      dispatch({ type: 'HIDELOGIN' })
    },
    Login: (username, password) => {
      dispatch({ type: 'LOGIN', username, password })
    },
    Logout: () => {
      dispatch({ type: 'LOGOUT' })
    },
  }
}

const ConnectedLogin = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)

export default ConnectedLogin
