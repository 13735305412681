
import { combineReducers } from 'redux';
import NavReducer from './nav';
import NowPlayingMoviesReducer from './nowPlayingMovies';
import Home from './home';
import Playlist from './playlist';
import Asignaturas from './asignaturas';
import BusquedaTexto from './busquedaTexto';
import ArbolCategorias from './arbolCategorias';
import Comentarios from './comentarios';
import UserMessage from './userMessage';
import Session from './session';
import Atributos from './atributos';
import Admin from './admin';
import counter from './counter';


export default function (initialState,action) {
  //console.log("initialState", initialState);
  //console.log("action", action);
  return combineReducers({
    nav: NavReducer,
    movies: NowPlayingMoviesReducer,
    home: Home,
    playlist: Playlist,
    asignaturas: Asignaturas,
    busquedaTexto: BusquedaTexto,
    arbolCategorias: ArbolCategorias,
    atributos: Atributos,
    comentarios: Comentarios,
    userMessage: UserMessage,
    session: Session,
    count: counter,
    admin: Admin,
    //triggerActions:function(state = {}, action){return state},
  });
}
