import React from "react"
import { connect } from "react-redux"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'


class UserMessage extends React.Component {
  constructor(props) {
    super(props);
  }


  handleClose = () => {
    this.props.CloseModal();
  }




  render() {
    const { userMessageState } = this.props;

    return (
      <>
        <Modal centered backdrop={true} id="UserMessage"
          size="md"
          show={userMessageState.show}
          onHide={this.handleClose}>
          < Modal.Header closeButton >
            <Modal.Title>{userMessageState.title}</Modal.Title>
          </Modal.Header >
          <Modal.Body>
            <p>{userMessageState.text}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}>Aceptar</Button>
          </Modal.Footer>
        </Modal >
      </>
    )
  }

}

const mapStateToProps = ({ userMessage }) => {
  return {
    userMessageState: userMessage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    CloseModal: () => {
      dispatch({ type: 'CLOSEUSERMESSAGE' })
    },
  }
}

const ConectedUserMessage = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMessage)



export default ConectedUserMessage


