import React from 'react'
import { connect } from "react-redux"
import { navigate } from 'gatsby'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

class ModalBusquedaTexto extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    query: ""
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }


  handleClose = () => {
    this.props.HideModalBusquedaTexto();
  }

  handleOpen = () => {
    this.props.ShowModalBusquedaTexto();
  }

  render() {
    const { showModal, resultadoBusqueda } = this.props.BusquedaTextoState;
    return (
      <>
        <a className="nav-link" onClick={this.handleOpen}>Buscar</a>

        <Modal backdrop={false} id="BuscadorTexto" show={showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex bd-highlight">
              <div className="p-2 bd-highlight searchTitleContainer"><span className="title">B&uacute;squeda</span></div>
              <div className="p-2 flex-grow-1 bd-highlight">
                <Form.Control
                  type="text"
                  value={this.state.query}
                  onKeyPress={(event) => {
                    if (event.charCode == 13) {
                      this.props.BuscarTexto(this.state.query);
                    }
                  }}
                  onChange={(event) => {
                    this.setState({ query: event.target.value })
                  }}


                  placeholder="Ingrese aquí el texto que desea buscar" />
              </div>
              <div className="p-2 bd-highlight">
                <Button
                  variant=""
                  onClick={() => {
                    this.props.BuscarTexto(this.state.query);
                  }}
                >
                  <i className="fa fa-search"></i>
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

      </>
    )
  }
}

const mapStateToProps = ({ busquedaTexto }) => {
  return {
    BusquedaTextoState: busquedaTexto
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ShowModalBusquedaTexto: () => {
      dispatch({ type: 'SHOWMODALBUSQUEDATEXTO' })
    },
    HideModalBusquedaTexto: () => {
      dispatch({ type: 'HIDEMODALBUSQUEDATEXTO' })
    },
    BuscarTexto: (query) => {
      dispatch({ type: 'BUSCARTEXTO', query })
      dispatch({ type: 'HIDEMODALBUSQUEDATEXTO' })
      navigate("/play/resultados");
    },
  }
}

const ConnectedModalBusquedaTexto = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalBusquedaTexto)

export default ConnectedModalBusquedaTexto
