import React from 'react'
import { CATEDRAIDCATEGORIA, ASIGNATURAIDCATEGORIA } from "../../utils/constantes"
import axios from "axios"
import { siteMetadata } from '../../../gatsby-config'
const baseurl = siteMetadata.apiBaseURL;

const MENSAJEERRORSETCATEGORIA = "Ocurrió un error y no se pudo asignar la categoría seleccionada, por favor intente de nuevo";

class NodoArbol extends React.Component {
  constructor(props) {
    super(props)
  }

  setCategory = (value, isChecked, currentNodo) => {
    //console.log("setCategory", value, isChecked);

    let newSelected = typeof (currentNodo.props.refArbol.state.selected) != "undefined" ?
      [...currentNodo.props.refArbol.state.selected]
      : [];
    //console.log(value);
    if (isChecked) {
      newSelected.push(value);
    } else {
      newSelected.splice(newSelected.indexOf(value), 1);
    }
    currentNodo.props.refArbol.setState({
      selected: newSelected
    });

  }

  handleCheckChange = (e) => {
    try {
      var value = e.target.value;
      const medio = this.props.medio;
      const self = this;
      const isChecked = e.target.checked;


      if (!isNaN(value)) {
        value = value * 1;
      }

      self.setCategory(value, isChecked, self);
      /*-------*/
      let url = baseurl + "/App_HttpHandlers/MobileAppHandler.ashx";
      axios
        .get(url, {
          params: {
            accion: "setcategoria",
            IdMedio: medio.IdMedio,
            IdCategoria: value
          }
        })
        .then(({ data }) => {
          //console.log("setcategoria", data);
          if (!data.Result) {
            /*----ROLBACK---*/
            self.setCategory(value, !isChecked, self);
            self.props.refArbol.props.Alert("Agregar categoría",
              MENSAJEERRORSETCATEGORIA);
          }


        })
        .catch(error => {
          console.log("ERROR", error);
          /*----ROLBACK---*/
          self.setCategory(value, !isChecked, self);
          self.props.refArbol.props.Alert("Agregar categoría",
            MENSAJEERRORSETCATEGORIA);

        })

    } catch (e) {
      console.log(e);
    }
  }

  render() {
    if (!this.props.nodo) return null;
    let keys = Object.keys(this.props.nodo)
    return (
      <ul>
        {
          keys.map((key, i) => {


            let nodo = this.props.nodo[key];
            if (nodo.IdCategoria == CATEDRAIDCATEGORIA) {
              return null;
            }

            let currentId = this.props.id + nodo.IdCategoria;
            let clases = [];

            if (nodo.mostrar) {
              clases.push("d-block")
            } else {
              clases.push("d-none")
            }



            return (<li key={i}
              className={clases.join(" ")}
            >
              {!nodo.hijos
                ?
                <div className="form-check">
                  <input id={currentId}
                    className="form-check-input"
                    checked={this.props.refArbol.state.selected.indexOf(nodo.IdCategoria) != -1}
                    onChange={this.handleCheckChange}
                    type="checkbox"
                    value={nodo.IdCategoria} />
                  <label
                    className="form-check-label"
                    htmlFor={currentId} >
                    {nodo.NombreCategoria}
                  </label>
                </div>
                :
                <span className="CategoriaPadre">{nodo.NombreCategoria}</span>
              }



              {/*<Link to={"/play/categoria/" + nodo.IdCategoria}>
              </Link>*/}

              {(typeof (nodo.hijos) != undefined
                && nodo.hijos
                && nodo.mostrar
                /*
                && nodo.IdCategoria != CATEDRAIDCATEGORIA
                && nodo.IdCategoria != ASIGNATURAIDCATEGORIA*/
              )
                ?
                <NodoArbol {...this.props} nodo={nodo.hijos} />
                : null}
            </li>)
          })
        }
      </ul>
    )
  }
}


export default NodoArbol 
