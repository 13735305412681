import React from 'react'
import { connect } from "react-redux"
import { Link } from 'gatsby'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

let keyPressTimeout = null;

class ModalAsignaturas extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    query: ""
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }


  handleClose = () => {
    this.props.HideModalAsignaturas();
  }

  handleOpen = () => {
    this.props.ShowModalAsignaturas();
  }

  render() {
    const { showModal, asignaturasResult, query } = this.props.AsignaturasState;
    return (
      <>
        <a className="nav-link" onClick={this.handleOpen}>Asignaturas</a>

        <Modal backdrop={false} id="BuscadorAsignaturas" show={showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="w-100 text-center">Asignaturas</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <div className="d-flex bd-highlight">
              <div className="p-2 flex-grow-1 bd-highlight">
                <Form.Control
                  type="text"
                  value={this.state.query}
                  onKeyPress={(event) => {
                    if (event.charCode == 13) {
                      this.props.BuscarAsignatura(this.state.query);
                    }
                  }}
                  onChange={(event) => {
                    if (keyPressTimeout) {
                      clearTimeout(keyPressTimeout);
                    }

                    this.setState({ query: event.target.value })
                    keyPressTimeout = setTimeout(() => {
                      this.props.BuscarAsignatura(this.state.query);
                    }
                      , 500);

                  }}


                  placeholder="Ingrese aquí el nombre de la asignatura que desea buscar" />


              </div>
              <div className="p-2 bd-highlight">
                <Button
                  variant=""
                  onClick={() => {
                    this.props.BuscarAsignatura(this.state.query);
                    return false;
                  }}
                >
                  <i className="fa fa-search"></i>
                </Button>
              </div>
            </div>

            {this.state.query ?
              <>
                <div className="ResultadosASignaturasLeyenda text-center mb-3">
                  Resultado de asignaturas con contenido Audiovisual
                  <span>Seleccione la asignatura para ver los materiales asociados</span>
                </div>
                <div className="p-3 ResultadosAsignaturas">
                  {asignaturasResult.length > 0 ?
                    asignaturasResult.map((asignatura, i) => {
                      return (
                        <div key={i}>
                          <Link to={"/play/categoria/" + asignatura.IdCategoria}
                            onClick={() => {
                              this.props.HideModalAsignaturas()
                            }}

                          >
                            {asignatura.NombreCategoria}
                          </Link>
                        </div>
                      )
                    })
                    :
                    <div>
                      No se encontraron asignaturas
                    </div>
                  }


                </div>
              </>
              :
              null
            }

          </Modal.Body>
          <Modal.Footer>

          </Modal.Footer>
        </Modal>

      </>
    )
  }
}

const mapStateToProps = ({ asignaturas }) => {
  return {
    AsignaturasState: asignaturas
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ShowModalAsignaturas: () => {
      //console.log("ShowModalAsignaturas")
      dispatch({ type: 'SHOWMODALASIGNATURAS' })
    },
    HideModalAsignaturas: () => {
      dispatch({ type: 'HIDEMODALASIGNATURAS' })
    },
    BuscarAsignatura: (query) => {
      dispatch({ type: 'BUSCARASIGNATURA', dispatch, query })
    },
  }
}

const ConnectedModalAsignaturas = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalAsignaturas)

export default ConnectedModalAsignaturas
