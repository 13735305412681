class LocalStorageManager {

  set = (key, val) => {
    if (typeof (localStorage) == "undefined") return null;

    if (typeof (val) == "object") {
      val = JSON.stringify(val)
    }
    localStorage.setItem(key, val);
  }

  get = (key) => {
    if (typeof (localStorage) == "undefined") return null;

    return localStorage.getItem(key);
  }

  remove = (key) => {
    if (typeof (localStorage) == "undefined") return null;
    localStorage.removeItem(key);
  }

  getAsObject = (key) => {
    if (typeof (localStorage) == "undefined") return null;

    let val = localStorage.getItem(key);
    let result = JSON.parse(val);
    if (result === undefined || result === null) {
      result = {}
    }
    return result
  } 
}

export { LocalStorageManager }
