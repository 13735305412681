import React from "react"


export default function ({ id,
  label,
  textAfterControl,
  type,
  rows,
  step,
  required,
  onChange,
  onBlur,
  getPropValue,
  editMedioState }) {

  let inputControl = null;

  switch (type) {
    case ("textarea"):
      inputControl =
        <textarea
          className="form-control-plaintext"
          id={id}
          rows={rows}
          required={required}
          value={getPropValue(id)}
          onChange={onChange}
          onBlur={onBlur}
        />
      break;

    default:
      inputControl =
        <input type={type}
          className={type == "number" ? "form-control-number" : "form-control-plaintext"}
          id={id}
          step={step}
          required={required}
          value={getPropValue(id)}
          onChange={onChange}
          onBlur={onBlur}
        />
      break;
  }


  textAfterControl === undefined ? null : textAfterControl;

  return (
    <div className="form-group row">
      <label
        htmlFor={id}
        className="col-sm-3 col-form-label"
      >
        {required ? <span className="text-danger">*</span> : null }
        <span>{label}</span>
      </label>
      <div className="col-sm-9">
        {inputControl}
        {textAfterControl}
        {editMedioState.medio
          && editMedioState.medio.validField !== undefined
          && editMedioState.medio.validField[id] !== undefined
          && !editMedioState.medio.validField[id] ?
          <div className="validationMessage text-danger">
            {`El campo ${label} es requerido`}
          </div>
          : null}
      </div>
    </div>

  )
}
