import React from "react"
import axios from "axios"
import { ATRIBUTOPREFIX } from "../../utils/constantes"
import { siteMetadata } from '../../../gatsby-config'

const qs = require('querystring');
const baseurl = siteMetadata.apiBaseURL;
const postConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

export default function ({
  atributo,
  onChange,
  editMedioState,
  setEditMedioState,
  requeridos }) {

  /* */

  const getAttrValue = (attrkey) => {
    let result = editMedioState.medio && editMedioState.medio.atributos !== undefined ?
      editMedioState.medio.atributos[attrkey] : "";
    return result === undefined || result == null ? "" : result;
  }

  const saveAttr = (Id, value) => {
    let url = baseurl + "/App_HttpHandlers/MobileAppHandler.ashx";
    let sendData = {
      accion: "SetAtributo",
      IdMedio: editMedioState.medio.IdMedio,
      IdAtributo: Id,
      ValorAtributo: value
    }

    sendData = qs.stringify(sendData)
    axios
      .post(url, sendData, postConfig)
      .then(({ data }) => {
        //console.log("savedAttr.data", data)

      })
      .catch(error => {
        console.log(error);
      })
  }


  const handleAttrInputBlur = (e) => {
    let newMedio = {
      ...editMedioState.medio,
    }

    let valid = e.target.validity.valid;
    newMedio.validField[e.target.id] = valid;

    if (!editMedioState.esMedioNuevo && valid) {
      saveAttr(e.target.dataset["attrId"], getAttrValue(e.target.id));
    }

    setEditMedioState({
      ...editMedioState,
      medio: newMedio
    })
  }


  /* --- */
  let attrkey = `${ATRIBUTOPREFIX}${atributo.Id}`;
  let inputControl = null;
  if (atributo.Obligatorio) {
    requeridos.push(attrkey)
  }

  switch (atributo.Tipo) {
    default:
      inputControl =
        <input type="text"
          className="form-control-plaintext"
          id={attrkey}
          data-attr-id={atributo.Id}
          value={getAttrValue(attrkey)}
          onChange={onChange}
          onBlur={handleAttrInputBlur}
          required={atributo.Obligatorio}
        />
      break;
  }

  return (
    <div className="form-group row">
      <label
        htmlFor={attrkey}
        className="col-sm-3 col-form-label"
      >
        <span className="text-danger">{atributo.Obligatorio ? "*" : null}</span>
        {atributo.Label}
      </label>
      <div className="col-sm-9">
        {inputControl}
        {editMedioState.medio
          && editMedioState.medio.validField !== undefined
          && editMedioState.medio.validField[attrkey] !== undefined
          && !editMedioState.medio.validField[attrkey] ?
          <div className="validationMessage text-danger">
            {`El campo ${atributo.Label} es requerido`}
          </div>
          : null
        }
      </div>
    </div>
  )
}
