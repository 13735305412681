module.exports = [{
      plugin: require('D:/CustomApps/UNED/mediateca/trunk/development/WebClient/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"linkImagesToOriginal":false,"wrapperStyle":"margin-bottom: 1.0725rem;"},
    },{
      plugin: require('D:/CustomApps/UNED/mediateca/trunk/development/WebClient/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('D:/CustomApps/UNED/mediateca/trunk/development/WebClient/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('D:/CustomApps/UNED/mediateca/trunk/development/WebClient/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
