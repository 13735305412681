import axios from "axios"
import { siteMetadata } from '../../gatsby-config'


function recibirAsignaturas(asignaturas,query) {
  return {
    type: "RECIBIRASIGNATURAS",
    asignaturas,
    query,
    receivedAt: Date.now()
  }
}

function errorOnrecibirAsignaturas(errorMessage) {
  return {
    type: "ERRORONRECIBIRASIGNATURAS",
    errorMessage
  }
}

// This data is fetched at run time on the client.
const fetchAsignaturas = (query, dispatch) => {
  if (query.length < 3) {
    return;
  }

  let url = siteMetadata.apiBaseURL + "/App_HttpHandlers/MobileAppHandler.ashx?accion=getasignaturas&Nombre=" + query
  axios
    .get(url)
    .then(({ data }) => {
      dispatch(recibirAsignaturas(data, query));
    })
    .catch(error => {
      console.log(error);
      dispatch(errorOnrecibirAsignaturas(error));
    })
}


export default function asignaturas(
  state = {
    showModal: false,
    loading: false,
    error: false,
    query:"",
    errorMessage: "",
    asignaturasResult: [],
    lastLoad: null,
  }, action) {
  switch (action.type) {
    case 'BUSCARASIGNATURA':
      fetchAsignaturas(action.query,action.dispatch)
      return {
        ...state,
        loading: true,
        error: false,
        asignaturasResult:[],
        errorMessage: null,
      }
    case 'RECIBIRASIGNATURAS':
      return {
        ...state,
        loading : false,
        error: false,
        errorMessage: null,
        query: action.query,
        asignaturasResult: action.asignaturas ? action.asignaturas : [],
        lastLoad : action.receivedAt
      }
    case 'ERRORONRECIBIRASIGNATURAS':
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
        asignaturasResult: [],
      }

    case "SHOWMODALASIGNATURAS":
      return {
        ...state,
        showModal: true
      }
    case "HIDEMODALASIGNATURAS":
      return {
        ...state,
        showModal: false
      }
    default:
      return state
  }




}
